.btn-outline-secondary {
  margin-bottom: 10px;
}

#bnbAmount {
  width:100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

#logo {
  width:100%;
  max-width:200px;
}

#header {
  width:100%;
  max-width: 600px;
}

#navbar {
  background-color: white;
}

.image {
  width:100%;
}

.blue {
  background-color: #0d6efd;
  color: white;
}